import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 16,
    stakingToken: tokens.xhaku,
    earningToken: tokens.haku,
    contractAddress: {
      43113: '',
      43114: '0xBA438A6F03c03fb1Cf86567F6bb866CCFc9B2da7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 17,
    stakingToken: tokens.xhaku,
    earningToken: tokens.bhc,
    contractAddress: {
      43113: '',
      43114: '0xbDe8a05313B74829da25cdB6804407Db34dea7df',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0.000116',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 18,
    stakingToken: tokens.xhaku,
    earningToken: tokens.dep,
    contractAddress: {
      43113: '',
      43114: '0x36C4D28DdE5E90318D95d1C1426e689C6079225f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0.218',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 19,
    stakingToken: tokens.xhaku,
    earningToken: tokens.stable,
    contractAddress: {
      43113: '',
      43114: '0x22A1bB905514E449EB056eb0C9a89B77cEE75A91',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0.0137',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 20,
    stakingToken: tokens.xhaku,
    earningToken: tokens.hps,
    contractAddress: {
      43113: '',
      43114: '0x4761d2364Ca09b301B7430b4C2ed9b12efBa788f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0.004025',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 21,
    stakingToken: tokens.xhaku,
    earningToken: tokens.dep,
    contractAddress: {
      43113: '',
      43114: '0xa94DC8577D08f413fE438868C009583589Ee7125',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0.139',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
]

export default pools
