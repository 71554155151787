const tokens = {
  avax: {
    symbol: 'AVAX',
    projectLink: 'https://www.avax.network/',
  },
  haku: {
    symbol: 'HAKU',
    address: {
      43114: '0x695Fa794d59106cEbd40ab5f5cA19F458c723829',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://hakuswap.com/',
  },
  xhaku: {
    symbol: 'xHAKU',
    address: {
      43114: '0xa95C238B5a72f481f6Abd50f951F01891130b441',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://hakuswap.com/',
  },
  wavax: {
    symbol: 'WAVAX',
    address: {
      43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://www.avax.network/',
  },
  usdt: {
    symbol: 'USDT.e',
    address: {
      43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      43113: '',
    },
    decimals: 6,
    projectLink: 'https://tether.to/',
  },
  usdc: {
    symbol: 'USDC.e',
    address: {
      43114: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      43113: '',
    },
    decimals: 6,
    projectLink: 'https://www.centre.io/',
  },
  dai: {
    symbol: 'DAI.e',
    address: {
      43114: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://makerdao.com/',
  },
  eth: {
    symbol: 'WETH.e',
    address: {
      43114: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://weth.io/',
  },
  btc: {
    symbol: 'WBTC.e',
    address: {
      43114: '0x50b7545627a5162F82A992c33b87aDc75187B218',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://www.wbtc.network/',
  },
  link: {
    symbol: 'LINK.e',
    address: {
      43114: '0x5947BB275c521040051D82396192181b413227A3',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  mim: {
    symbol: 'MIM',
    address: {
      43114: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://abracadabra.money/',
  },
  bhc: {
    symbol: 'BHC',
    address: {
      43114: '0xa8752333f6a6fe47323a4eDAC3D09Bb1048A0E23',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://billionhappiness.finance/',
  },
  dep: {
    symbol: 'DEP',
    address: {
      43114: '0xD4d026322C88C2d49942A75DfF920FCfbC5614C1',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://dea.sg/',
  },
  stable: {
    symbol: '5TABLE',
    address: {
      43114: '0xEF3a28035fE545cB4c86e110f1B72e3fC6a21206',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://5table.finance/',
  },
  hps: {
    symbol: 'HPS',
    address: {
      43114: '0xd9A807E9d2F61B0098B4c3f79b2Dfb9Bc2E181ae',
      43113: '',
    },
    decimals: 18,
    projectLink: 'https://billionhappiness.finance/',
  },
}

export default tokens
