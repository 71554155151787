import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 17,
    lpSymbol: 'HAKU-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x7943Acd42c41a345841cB22Bd846794a22d8682d',
    },
    token: tokens.haku,
    quoteToken: tokens.wavax,
  },
  {
    pid: 18,
    lpSymbol: 'USDC.e-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x519DE4668EA6661D1870928a3033A62dc2ACc503',
    },
    token: tokens.wavax,
    quoteToken: tokens.usdc,
  },
  {
    pid: 19,
    lpSymbol: 'USDT.e-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x1F0bC5c91518D903c0c097BdE9741746B4423008',
    },
    token: tokens.wavax,
    quoteToken: tokens.usdt,
  },
  {
    pid: 31,
    lpSymbol: 'BHC-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x014C13e4e734b90cDe56f96F42E7227Fc2b2e86E',
    },
    token: tokens.bhc,
    quoteToken: tokens.wavax,
  },
  {
    pid: 20,
    lpSymbol: 'WETH.e-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x605CB4dcad3FD553f97FBAA72A6a0306F954282B',
    },
    token: tokens.eth,
    quoteToken: tokens.wavax,
  },
  {
    pid: 21,
    lpSymbol: 'WBTC.e-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x29E144EA1AbaC02B62be7aFB877D1bBacA141295',
    },
    token: tokens.btc,
    quoteToken: tokens.wavax,
  },
  {
    pid: 22,
    lpSymbol: 'WETH.e-WBTC.e LP',
    lpAddresses: {
      43113: '',
      43114: '0x6075ECCaDFC2917d58062af55090b6Bd3dE258F5',
    },
    token: tokens.btc,
    quoteToken: tokens.eth,
  },
  {
    pid: 23,
    lpSymbol: 'WETH.e-USDC.e LP',
    lpAddresses: {
      43113: '',
      43114: '0x6C2782d9632EFD35e93D33E25bA75C118682954C',
    },
    token: tokens.eth,
    quoteToken: tokens.usdc,
  },
  {
    pid: 24,
    lpSymbol: 'WETH.e-USDT.e LP',
    lpAddresses: {
      43113: '',
      43114: '0x3152e0Dd889045595D7635D8fc41965Cea6209f2',
    },
    token: tokens.eth,
    quoteToken: tokens.usdt,
  },
  {
    pid: 25,
    lpSymbol: 'WBTC.e-USDC.e LP',
    lpAddresses: {
      43113: '',
      43114: '0xEFfFF893f9423FDB7a5c010cEf202EA5A52575a6',
    },
    token: tokens.btc,
    quoteToken: tokens.usdc,
  },
  {
    pid: 26,
    lpSymbol: 'WBTC.e-USDT.e LP',
    lpAddresses: {
      43113: '',
      43114: '0x6F6a5F63Cc7b7ddcAD76752E633d45F3D5EfB0E2',
    },
    token: tokens.btc,
    quoteToken: tokens.usdt,
  },
  {
    pid: 27,
    lpSymbol: 'USDT.e-USDC.e LP',
    lpAddresses: {
      43113: '',
      43114: '0xFFA26cb3458023C4B78c3d10F8bEF4704C2fd198',
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
  },
  {
    pid: 28,
    lpSymbol: 'AVAX-DAI.e LP',
    lpAddresses: {
      43113: '',
      43114: '0x349125b7A60ddA7A429C46117A5472A698a5c34B',
    },
    token: tokens.wavax,
    quoteToken: tokens.dai,
  },
  {
    pid: 29,
    lpSymbol: 'AVAX-LINK.e LP',
    lpAddresses: {
      43113: '',
      43114: '0xff2BBf7b5E28Ef283D9FB610b19f189017DC72e9',
    },
    token: tokens.link,
    quoteToken: tokens.wavax,
  },
  {
    pid: 30,
    lpSymbol: 'MIM-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x766B3A39Fb4E90cd9a37281C28104DfDE68aC0F6',
    },
    token: tokens.mim,
    quoteToken: tokens.wavax,
  },
  {
    pid: 32,
    lpSymbol: 'DEP-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0xeb7a67E0ea0Ec080F9aA0486423595102F8fe061',
    },
    token: tokens.dep,
    quoteToken: tokens.wavax,
  },
  {
    pid: 33,
    lpSymbol: '5TABLE-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x11a40cac713A7E59d25d8CAd2fA619A3F2Ede968',
    },
    token: tokens.stable,
    quoteToken: tokens.wavax,
  },
  {
    pid: 34,
    lpSymbol: 'HPS-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x58026e9A57DC0D24c1257868534561226A629bfe',
    },
    token: tokens.hps,
    quoteToken: tokens.wavax,
  },
]

export default farms
