import { MenuEntry } from 'hakuswap-uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: 'https://exchange.hakuswap.com/#/swap',
      },
      {
        label: t('Liquidity'),
        href: 'https://exchange.hakuswap.com/#/pool',
      },
      {
        label: 'Zap',
        href: 'https://exchange.hakuswap.com/#/zap',
      },
    ],
  },
  // {
  //   label: t('Competition'),
  //   icon: 'LotteryIcon',
  //   items: [
  //     {
  //       label: t('HAKU Token'),
  //       href: '/campaigns/0',
  //     },
  //   ],
  // },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Staking'),
    icon: 'PoolIcon',
    href: '/xhaku',
  },
  // {
  //   label: t('Vaults'),
  //   icon: 'VaultIcon',
  //   href: '/vaults',
  // },
  // {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'Bridge',
  //   icon: 'BridgeIcon',
  //   href: '/bridge',
  // },
  // {
  //   label: t('NFT Marketplace'),
  //   icon: 'NftIcon',
  //   href: '/marketplace',
  // },
  {
    label: t('Mines of Haku'),
    icon: 'VaultIcon',
    href: 'https://minesofhaku.com',
  },
  {
    label: 'The Battle of Haku',
    icon: 'LotteryIcon',
    href: 'https://battle.hakuswap.com/stake',
  },
  {
    label: 'Token Sales',
    icon: 'IfoIcon',
    href: '/iho',
  },
  {
    label: t('Info'),
    icon: 'InfoIcon',
    href: 'https://info.hakuswap.com/home',
  },
  {
    label: t('Multisender'),
    icon: 'DisperseIcon',
    href: '/disperse',
  },
  {
    label: t('Partnership'),
    icon: 'PartnerIcon',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSePw9y_LsviBJTPSMH2rTXfMb4LD7c0TSdtwY1KHZRTgXdJJw/viewform',
  },
  {
    label: t('Audit'),
    icon: 'AuditIcon',
    href: 'https://docs.hakuswap.com/audits',
    target: '_blank',
  },
  // {
  //   label: 'Listing',
  //   icon: 'ReferralIcon',
  //   items: [
  //     {
  //       label: 'Snowtrace Explorer',
  //       href: 'https://snowtrace.io/token/0x695Fa794d59106cEbd40ab5f5cA19F458c723829',
  //       target: '_blank',
  //     },
  //     {
  //       label: 'DappRadar',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: '#',
  //     },
  //   ],
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Docs'),
        href: 'https://docs.hakuswap.com',
        target: '_blank',
      },
      {
        label: t('Blog'),
        href: 'https://hakuswap.medium.com/',
        target: '_blank',
      },
      // {
      //   label: t('Vote'),
      //   href: 'https://vote.hakuswap.com/',
      //   target: '_blank',
      // },
    ],
  },
]

export default config
