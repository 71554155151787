export default {
  masterChef: {
    43113: '',
    43114: '0xBA438A6F03c03fb1Cf86567F6bb866CCFc9B2da7',
  },
  sousChef: {
    43113: '',
    43114: '0xc21c4E31208F8092b7aae6bD8Bd309D6B041358b',
  },
  lottery: {
    43113: '',
    43114: '0xBcA4819ab95a929e1b378687bd218d34D14F5f83',
  },
  lotteryNFT: {
    43113: '',
    43114: '0x99d11EaF2f38777bb6062CB37f9528BFf9E6607E',
  },
  mulltiCall: {
    43114: '0x98e2060F672FD1656a07bc12D7253b5e41bF3876',
    43113: '',
  },
  multi: {
    43114: '0x8e31c5B0Fe02a8113F70bC0c2aE8dF81734e459E',
    43113: '',
  },
  pancakeProfile: {
    43114: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    43113: '',
  },
  pancakeRabbits: {
    43114: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    43113: '',
  },
  bunnyFactory: {
    43114: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    43113: '',
  },
  claimRefund: {
    43114: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    43113: '',
  },
  pointCenterIfo: {
    43114: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    43113: '',
  },
  bunnySpecial: {
    43114: '0xFee8A195570a18461146F401d6033f5ab3380849',
    43113: '',
  },
  gfce: {
    43114: '0x0DE624ED2882E7480d97b2bBee57675C4FA26e33',
    43113: '',
  },
  xhaku: {
    43114: '0xa95C238B5a72f481f6Abd50f951F01891130b441',
    43113: '',
  },
  factory: {
    43114: '0x2Db46fEB38C57a6621BCa4d97820e1fc1de40f41',
    43113: '',
  },
  nftMarketplace: {
    43113: '',
    43114: '0xcF3bD10E50a5B69Da7eDAdEf63bbD5936f2e0185',
  },
}
