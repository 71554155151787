import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'haku',
    address: '0x02DBCf89a014835df027A5A1153eEB7F5E791834',
    isActive: true,
    name: 'The Best AMM+NFT DEX on Avalanche',
    description:
      'HakuSwap is a crypto world for users to trade, earn, and game. It is the premier choice for projects on Avalanche with features including AMM, NFT, and GameFi.',
    launchDate: 'Feb. 7',
    launchTime: '5 PM GMT',
    saleAmount: '1,250,000 HAKU',
    raiseAmount: '$500,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://hakuswap.com',
    currency: 'USDC.e',
    currencyAddress: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    currencyDecimals: 6,
    tokenDecimals: 18,
    tokenSymbol: 'HAKU',
    releaseTime: 1637748343,
    campaignId: '1642486108',
    maxDepositAmount: 5000,
  },
]

export default ifos
