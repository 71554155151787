import { useEffect, useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { getHakuAddress, getXhakuAddress } from 'utils/addressHelpers'
import { xhakuStake, xhakuUnstake } from 'utils/callHelpers'
import useRefresh from './useRefresh'
import { useERC20, useXhaku } from './useContract'
import useGasBoost from './useGasBoost'

const useHakuInfo = () => {
  const [totalLockedHaku, setTotalLockedHaku] = useState(new BigNumber(0))
  const [xhakuRatio, setXhakuRatio] = useState(new BigNumber(0))
  const [delayToWithdraw, setDelayToWithdraw] = useState(0)
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const xhakuContract = useXhaku()
  const hakuContract = useERC20(getHakuAddress())

  useEffect(() => {
    const fetchInfo = async () => {
      const [res0, res1, res2] = await Promise.all([
        hakuContract.methods.balanceOf(getXhakuAddress()).call(),
        xhakuContract.methods.xHAKUForHAKU(10000).call(),
        xhakuContract.methods.delayToWithdraw().call(),
      ])
      setTotalLockedHaku(new BigNumber(res0).div(1e18))
      setXhakuRatio(new BigNumber(res1).div(1e4))
      setDelayToWithdraw(Number(res2))
    }
    fetchInfo()
  }, [account, xhakuContract, hakuContract, fastRefresh])

  return { totalLockedHaku, xhakuRatio, delayToWithdraw }
}

export const useUserInfo = () => {
  const [hakuBalance, setHakuBalance] = useState(new BigNumber(0))
  const [xhakuBalance, setXhakuBalance] = useState(new BigNumber(0))
  const [claimableAmount, setClaimableAmount] = useState(new BigNumber(0))
  const [stakedTime, setStakedTime] = useState(0)
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const xhakuContract = useXhaku()
  const hakuContract = useERC20(getHakuAddress())

  useEffect(() => {
    const fetchInfo = async () => {
      const [res0, res1, res2, res3] = await Promise.all([
        hakuContract.methods.balanceOf(account).call(),
        xhakuContract.methods.balanceOf(account).call(),
        xhakuContract.methods.HAKUBalance(account).call(),
        xhakuContract.methods.stakedTime(account).call(),
      ])
      setHakuBalance(new BigNumber(res0))
      setXhakuBalance(new BigNumber(res1))
      setClaimableAmount(new BigNumber(res2).div(1e18))
      setStakedTime(Number(res3))
    }
    if (account) {
      fetchInfo()
    }
  }, [account, xhakuContract, hakuContract, fastRefresh])

  return { hakuBalance, xhakuBalance, claimableAmount, stakedTime }
}

export const useXhakuStake = () => {
  const { account } = useWeb3React()
  const xhakuContract = useXhaku()

  const { gasBoostedPrice } = useGasBoost()
  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await xhakuStake(xhakuContract, amount, account, gasBoostedPrice)
      console.info(txHash)
    },
    [account, xhakuContract, gasBoostedPrice],
  )

  return { onStake: handleStake }
}

export const useXhakuUnstake = () => {
  const { account } = useWeb3React()
  const xhakuContract = useXhaku()
  const { gasBoostedPrice } = useGasBoost()

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await xhakuUnstake(xhakuContract, amount, account, gasBoostedPrice)
      console.info(txHash)
    },
    [account, xhakuContract, gasBoostedPrice],
  )

  return { onUnstake: handleUnstake }
}

export default useHakuInfo
